
 <script>
    import FillingCircle from '@/components/Loaders/FillingCircle.vue'

    export default {
        data(){
            return {
                deleting: false,
                reason: "",
            }
        },

        components: {
            FillingCircle
            
        },

        mounted(){
            console.log()
        },

        computed:{
            id: function() {return this.$route.params.id},
            itemId: function() { return this.$route.params.itemId },
            deleteItemSignal: function() {
                return this.$store.dispatch({ type: 'orders/deleteOrderItem', itemId: this.itemId, id: this.id, trash_reason: this.reason})
            }
        },
        methods: {
            deleteItem: function() {
                this.deleting = true;
                this.deleteItemSignal.then(() => {
                    this.deleting = false
                    this.$router.go(-1);
                }).catch(error => {
                    this.deleting = false
                    
                    this.$notify({
                        title: "Failed to delete order item",
                        text: error,
                        type:"error",
                    });
                })
            }
        }
    }
    
</script>

<template>
    <div class="modal__input-area">
        <div class="modal__question"> Are sure you want to delete?</div>
        <div class="modal__input-container">
            <textarea id="edit-id-description" class="modal__description" v-model="reason"></textarea>
        
            <label class="modal__label" for="edit-id-description">Reason</label>
        </div>
        <div class="modal__buttons-container">
            <button @click="deleteItem" class="modal__button modal__button--delete" >
                <div v-if="deleting"><filling-circle :size="16" /></div>
                <div style="padding-left:1em;">Don't</div>
            </button>
        </div>
    </div>
 </template>

